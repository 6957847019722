import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/styles.css';
import './css/styles_custom.css';
import './css/webfonts/stylesheet.css';
import './css/icons.css';

import Index from './pages/Index';
import Auth from './pages/Auth';
import FlashFire from './pages/FlashFire';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          
          <Route path="*" element={<Index/>}/>
          <Route path="/" element={<Index/>}/>
          <Route path="/auth" element={<Auth/>}/>
          <Route path="/products/flashfire" element={<FlashFire/>}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;