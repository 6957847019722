import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';

import video1 from '../videos/video1.mov'
import video1bg from '../videos/video1.jpg'

const Index = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    return (
    <>
        <Header/>

        <div className="index-section" style={{ position: 'relative', overflow: 'hidden', backgroundImage: `url(${video1bg})` }}>
            <video autoPlay loop muted className="index-content-video"><source src={video1} type="video/mp4"/></video>
            <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)' }} />
            <div className="index-content-header fadeout">
                <p style={{fontSize: '48px'}}><b>Flash Fire</b></p>
                <p style={{marginTop: '-20px'}}>Блок управления повторителями</p>
            </div>
            <div className="index-content-footer fadeout">
                <Link className="index-content-button" to="/products/flashfire">Узнать больше</Link>
            </div>
        </div>

       {/* <div className="index-section" style={{ position: 'relative', overflow: 'hidden', backgroundImage: `url(${touge1bg})` }}>
            <video autoPlay loop muted className="index-content-video"><source src={touge1} type="video/mp4"/></video>
            <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)' }} />
            <div className="index-content-header">
                <p style={{fontSize: '48px'}}><b>Flash Fire</b></p>
                <p style={{marginTop: '-20px'}}>Блок управления повторителями</p>
            </div>
            <div className="index-content-footer">
                <a className="index-content-button">Узнать больше</a>
                <a style={{marginLeft:'15px'}} className="index-content-button">Что-то ещё</a>
            </div>
        </div>*/}
    </>
    );
};
export default Index;